import { PRIMARY_STORAGE_KEY_COOKIE } from 'kat/lib/constants/cookieConstants'
import { appStylesBase } from 'kat/lib/styles/appStylesBase'
import { appStylesBaseDefault } from 'kat/lib/styles/appStylesBaseDefault'
import { cookieStore } from 'kat/lib/utilities/cookieStore'
import { CookieStorage } from '../types/CookieStorage'
import { AppStyles } from './types/AppStyles'

const cookieValues: CookieStorage | undefined = cookieStore.get(
  PRIMARY_STORAGE_KEY_COOKIE
)

const setAppTheme = (theme: CookieStorage['uiColorTheme']) => {
  const values: AppStyles =
    theme === 'DarkMode'
      ? {
          ...appStylesBaseDefault,
          linkColor: {
            lightest: '#fff',
            lighter: '#b9bbbe',
            light: '#96989d',
            medium: '#e2e2e3',
            dark: '#e2e2e3',
            darker: '#e2e2e3',
            darkest: '#000',
          },
          textColor: {
            ...appStylesBaseDefault.textColor,
            primary: {
              lightest: '#fff',
              lighter: '#b9bbbe',
              light: '#afb2b9',
              medium: '#fff ',
              dark: '#000',
              darker: '#000',
              darkest: '#000',
            },
            brand: {
              lightest: '#fff',
              lighter: '#fff',
              light: appStylesBaseDefault.textColor.brand.lighter,
              medium: appStylesBaseDefault.textColor.brand.light,
              dark: appStylesBaseDefault.textColor.brand.medium,
              darker: appStylesBaseDefault.textColor.brand.dark,
              darkest: appStylesBaseDefault.textColor.brand.darker,
            },
            success: {
              lighter: '#51b474',
              light: '#51b474',
              medium: '#07ef58',
              dark: '#408f5c',
              darker: '#408f5c',
            },
            danger: {
              lighter: '#cb3543',
              light: '#cb3543',
              medium: '#cb3543',
              dark: '#b9303d',
              darker: '#b9303d',
            },
            warning: {
              lighter: '#ffdc00',
              light: '#e5c603',
              medium: '#c1a600',
              dark: '#b29900',
              darker: '#988300',
            },
          },
          borderColor: {
            ...appStylesBaseDefault.borderColor,
            lighter: '#dfe1e5',
            light: '#a2a2a4',
            medium: '#9c9da0',
            dark: '#838487',
            darker: '#59595c',
          },

          backgroundColor: {
            ...appStylesBaseDefault.backgroundColor,
            primary: {
              ...appStylesBaseDefault.backgroundColor.primary,
              lighter: '#cfc4ed',
              light: '#ac95ef',
              medium: '#7950f2',
              dark: '#5431bd',
              darker: '#3d2389',
            },
            neutral: {
              lightest: '#4f525a',
              lighter: '#42444c',
              light: '#41454b',
              medium: '#2b2f33',
              dark: '#202225',
              darker: '#222',
              darkest: '#222',
            },
            warning: {
              lighter: '#f8f863',
              light: '#c6c603',
              medium: '#aeae03',
              dark: '#8e8e03',
              darker: '#7a7a03',
            },
          },
        }
      : {
          ...appStylesBaseDefault,
          backgroundColor: {
            ...appStylesBaseDefault.backgroundColor,
            neutral: {
              ...appStylesBaseDefault.backgroundColor.neutral,
            },
          },
          textColor: {
            ...appStylesBaseDefault.textColor,
          },
        }

  return appStylesBase.extends<AppStyles>(values)()
}

let styles: AppStyles = setAppTheme(cookieValues?.uiColorTheme)

export const appStyles = () => {
  return styles
}

export const setAppStyles = (theme: CookieStorage['uiColorTheme']) => {
  styles = setAppTheme(theme)
}
