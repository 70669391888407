import { StateReducers } from 'state/reducers/types/StateReducers'
import { appRoutes } from 'functions/routes/appRoutes'
import { getCurrentTaskId } from './getCurrentTaskId'
import { AppRoute } from 'kat/lib/routes/types/AppRoute'
import { UrlQuery } from '../../functions/routes/types/UrlQuery'
import { getWhichMain } from './getWhichMain'
import { SCRATCH_PAD_KEY } from 'functions/constants/miscContants'
import { getIsDailyLogId } from 'utilities/get/getIsDailyLogId'

interface Props {
  state: StateReducers
}

interface Return {
  route?: AppRoute
  query?: UrlQuery
  withCurrentQuery?: boolean
}

export const getBackLocation = ({ state }: Props): Return => {
  const currentLocation = state.currentLocation || undefined
  const isRoutes = currentLocation && currentLocation.isRoutes
  const workspaceId = state.currentWorkspaceId || ''
  const taskId = getCurrentTaskId({ state })
  const taskMainRoute = isRoutes?.taskMainRoute
  const workspaceSettingsRoute = isRoutes?.workspaceSettingsRoute
  const accountSettingsRoute = isRoutes?.accountSettingsRoute
  const membersRoute = isRoutes?.membersRoute
  const updateDisplayNameRoute = isRoutes?.updateDisplayNameRoute
  const updatePasswordRoute = isRoutes?.updatePasswordRoute
  const addNewLabelRoute = isRoutes?.addNewLabelRoute
  const labelsRoute = isRoutes?.labelsRoute
  const activityNotificationRoute = isRoutes?.activityNotificationRoute
  const workspaceFilesRoute = isRoutes?.workspaceFilesRoute
  const notificationSettingsRoute = isRoutes?.notificationSettingsRoute
  const upgradeWorkspaceRoute = isRoutes?.upgradeWorkspaceRoute
  const welcomeToProRoute = isRoutes?.welcomeToProRoute
  const workspaceSubscriptionRoute = isRoutes?.workspaceSubscriptionRoute
  const billingRoute = isRoutes?.billingRoute
  const proCanceledRoute = isRoutes?.proCanceledRoute
  const updateAvatarRoute = isRoutes?.updateAvatarRoute
  const deleteWorkspaceRoute = isRoutes?.deleteWorkspaceRoute
  const reportRoute = isRoutes?.reportRoute
  const updateWorkspaceNameRoute = isRoutes?.updateWorkspaceNameRoute
  const paymentFailedRoute = isRoutes?.paymentFailedRoute
  const whichMain = getWhichMain({ state })
  const createUserRoute = isRoutes?.createUserRoute
  const resetPasswordRoute = isRoutes?.resetPasswordRoute
  const updateWorkspaceLogoRoute = isRoutes?.updateWorkspaceLogoRoute
  const memberDetailRoute = isRoutes?.memberDetailRoute
  const inviteDetailRoute = isRoutes?.inviteDetailRoute
  const emailLoginRoute = isRoutes?.emailLoginRoute
  const deleteUserAccountRoute = isRoutes?.deleteUserAccountRoute
  const darkModeRoute = isRoutes?.darkModeRoute
  const leaveWorkspaceRoute = isRoutes?.leaveWorkspaceRoute
  const calendarRoute = isRoutes?.calendarRoute
  const activitiesRoute = isRoutes?.activitiesRoute
  const archiveRoute = isRoutes?.archiveRoute
  const levelRoute = isRoutes?.levelRoute

  if (proCanceledRoute || paymentFailedRoute) {
    return {
      route: appRoutes.postLoginHomeRoute,
      query: { workspaceId },
    }
  }

  if (accountSettingsRoute || inviteDetailRoute || memberDetailRoute) {
    if (workspaceId) {
      return {
        route: appRoutes.membersRoute,
        query: { workspaceId },
      }
    } else {
      return {
        route: appRoutes.selectWorkspaceRoute,
      }
    }
  }

  if (addNewLabelRoute) {
    return {
      route: appRoutes.labelsRoute,
      query: { workspaceId },
    }
  }

  if (
    updateDisplayNameRoute ||
    updatePasswordRoute ||
    notificationSettingsRoute ||
    billingRoute ||
    updateAvatarRoute ||
    deleteUserAccountRoute ||
    darkModeRoute
  ) {
    return {
      route: appRoutes.accountSettingsRoute,
      query: { workspaceId },
    }
  }

  if (
    membersRoute ||
    labelsRoute ||
    workspaceFilesRoute ||
    upgradeWorkspaceRoute ||
    welcomeToProRoute ||
    workspaceSubscriptionRoute ||
    deleteWorkspaceRoute ||
    reportRoute ||
    updateWorkspaceNameRoute ||
    updateWorkspaceLogoRoute ||
    leaveWorkspaceRoute
  ) {
    return {
      route: appRoutes.workspaceSettingsRoute,
      query: { workspaceId },
    }
  }

  if (activitiesRoute) {
    return {
      route: appRoutes.taskMainRoute,
      query: { workspaceId, taskId: SCRATCH_PAD_KEY },
    }
  }

  if (levelRoute) {
    return {
      route: appRoutes.activitiesRoute,
      query: { workspaceId },
    }
  }

  if (taskMainRoute) {
    if (taskId === SCRATCH_PAD_KEY || getIsDailyLogId(taskId)) {
      return {
        route: appRoutes.calendarRoute,
        withCurrentQuery: false,
        query: {
          workspaceId,
        },
      }
    } else if (whichMain === 'calendar') {
      return {
        route: appRoutes.calendarRoute,
        query: {
          workspaceId,
        },
        withCurrentQuery: false,
      }
    } else if (whichMain === 'activities') {
      return {
        route: appRoutes.activitiesRoute,
        query: {
          workspaceId,
        },
        withCurrentQuery: false,
      }
    } else if (whichMain === 'archive') {
      return {
        route: appRoutes.archiveRoute,
        query: {
          workspaceId,
          whichMain,
        },
        withCurrentQuery: false,
      }
    } else if (whichMain === 'level') {
      return {
        route: appRoutes.levelRoute,
        query: {
          workspaceId,
        },
        withCurrentQuery: false,
      }
    } else {
      return {
        route: appRoutes.workspaceHomeRoute,
        withCurrentQuery: false,
        query: {
          workspaceId,
        },
      }
    }
  }

  if (
    archiveRoute ||
    calendarRoute ||
    workspaceSettingsRoute ||
    activityNotificationRoute
  ) {
    return {
      route: appRoutes.workspaceHomeRoute,
      query: { workspaceId },
    }
  }

  if (emailLoginRoute) {
    return {
      route: appRoutes.loginRoute,
    }
  }

  if (createUserRoute || resetPasswordRoute) {
    return {
      route: appRoutes.emailLoginRoute,
    }
  }

  return {}
}
